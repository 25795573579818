@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?54828472');
  src: url('../font/fontello.eot?54828472#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?54828472') format('woff2'),
       url('../font/fontello.woff?54828472') format('woff'),
       url('../font/fontello.ttf?54828472') format('truetype'),
       url('../font/fontello.svg?54828472#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?54828472#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-fowlit:before { content: '\e800'; } /* '' */
.icon-phone:before { content: '\e801'; } /* '' */
.icon-trash:before { content: '\e802'; } /* '' */
.icon-drawer:before { content: '\e803'; } /* '' */
.icon-settings:before { content: '\e804'; } /* '' */
.icon-search:before { content: '\e805'; } /* '' */
.icon-home:before { content: '\e806'; } /* '' */
.icon-right:before { content: '\e807'; } /* '' */
.icon-level:before { content: '\e808'; } /* '' */
.icon-flow:before { content: '\e809'; } /* '' */
.icon-plus:before { content: '\e80a'; } /* '' */
.icon-minus:before { content: '\e80b'; } /* '' */
.icon-tags:before { content: '\e80c'; } /* '' */
.icon-camp:before { content: '\e80d'; } /* '' */
.icon-up-dir:before { content: '\e80e'; } /* '' */
.icon-down-dir:before { content: '\e80f'; } /* '' */
.icon-calendar:before { content: '\e810'; } /* '' */
.icon-users:before { content: '\e811'; } /* '' */
.icon-user:before { content: '\e812'; } /* '' */
.icon-cancel-circled:before { content: '\e813'; } /* '' */
.icon-save:before { content: '\e814'; } /* '' */
.icon-edit:before { content: '\e815'; } /* '' */
.icon-reload:before { content: '\e816'; } /* '' */
.icon-basket:before { content: '\e817'; } /* '' */
.icon-electricity:before { content: '\e818'; } /* '' */
.icon-dashboard:before { content: '\e819'; } /* '' */
.icon-book:before { content: '\e81a'; } /* '' */
.icon-chart-line:before { content: '\e81b'; } /* '' */
.icon-box:before { content: '\e81c'; } /* '' */
.icon-link:before { content: '\e81d'; } /* '' */
.icon-spin:before { content: '\e830'; } /* '' */
.icon-kitchen:before { content: '\e832'; } /* '' */
.icon-check:before { content: '\f06d'; } /* '' */
.icon-copy:before { content: '\f0c5'; } /* '' */
.icon-menu:before { content: '\f0c9'; } /* '' */
.icon-mail:before { content: '\f0e0'; } /* '' */
.icon-conference:before { content: '\f0e6'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-unlink:before { content: '\f127'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
.icon-support:before { content: '\f1cd'; } /* '' */
.icon-fields:before { content: '\f1e3'; } /* '' */
.icon-wifi:before { content: '\f1eb'; } /* '' */
.icon-bed:before { content: '\f236'; } /* '' */
.icon-tv:before { content: '\f26c'; } /* '' */
.icon-lock:before { content: '\f512'; } /* '' */
.icon-lock-open:before { content: '\f513'; } /* '' */
.icon-sweden:before { content: '\f601'; } /* '' */